import { reactive } from '@vue/composition-api'
import { toastError } from '../../CustomComponents/DataTable/utils'

const incomingMailsStore = reactive({
  currentDocument: null,
  mailToClassify: null,
  isMailClassifyModalOpen: false,
  isDocumentClassifyModalOpen: false,
  rows: {
    list: [],
    current: null,
    pined: [],
  },
  pagination: {
    page: 0,
    pages: 0,
    size: 10,
    total: 0,
    order: 'd',
    orderField: '',
    search: '',
    isLoading: false,
    lang: '',
    status: '',
    filtersValues: {},
    filters: {},
    method: 'get',
  },
  requests: {
    controller: null,
  },
})

export default function useIncomingMailStore() {
  return {
    store: incomingMailsStore,
    pin: mail => {
      incomingMailsStore.rows.pined.push(mail)
    },
    unpin: email => {
      incomingMailsStore.rows.pined = incomingMailsStore.rows.pined.filter(mail => mail.email_id !== email.email_id)
    },
    isPined: email => incomingMailsStore.rows.pined.map(mail => mail.email_id).includes(email.email_id),
    togglePin: email => {
      if (incomingMailsStore.rows.pined.map(mail => mail.email_id).includes(email.email_id)) {
        incomingMailsStore.rows.pined = incomingMailsStore.rows.pined.filter(mail => mail.email_id !== email.email_id)
      } else {
        incomingMailsStore.rows.pined.push(email)
      }
    },
    listData: async ({
      api,
      url,
      resolveData,
      infiniteLoadStyle,
      loadMore,
      toast,
    }) => {
      const hasNextPage = incomingMailsStore.pagination.page <= incomingMailsStore.pagination.pages
      if (loadMore && (!hasNextPage || incomingMailsStore.pagination.isLoading)) return
      // const previousPaginationState = incomingMailsStore.pagination
      if (infiniteLoadStyle && loadMore) {
        incomingMailsStore.pagination.page++
      }
      const _payload = {
        size: incomingMailsStore.pagination.size === 0 ? 1000000 : incomingMailsStore.pagination.size,
        page: incomingMailsStore.pagination.page || 1,
        query: incomingMailsStore.pagination.search,
        lang: incomingMailsStore.pagination.lang,
        order: incomingMailsStore.pagination.order,
        filter: incomingMailsStore.pagination.status,
        order_field: incomingMailsStore.pagination.orderField,
        ...(incomingMailsStore.pagination.filtersValues || {}),
      }
      const payload = {}
      Object.keys(_payload).forEach(key => {
        if (_payload[key] === 0 || _payload[key]) {
          payload[key] = _payload[key]
        }
      })
      incomingMailsStore.pagination.isLoading = true
      if (incomingMailsStore.requests.controller) {
        incomingMailsStore.requests.controller.abort()
      }
      incomingMailsStore.requests.controller = new AbortController()
      try {
        const { data } = await api[String(incomingMailsStore.pagination.method || 'get').toLowerCase()](url, { params: payload, signal: incomingMailsStore.requests.controller.signal })
        incomingMailsStore.rows.list = incomingMailsStore.rows.list.concat(resolveData(data))
        incomingMailsStore.pagination.total = data?.total || 1
        incomingMailsStore.pagination.page = data?.page || 1
        incomingMailsStore.pagination.size = data?.size || 1
        incomingMailsStore.pagination.pages = data?.pages || Math.ceil(incomingMailsStore.pagination.total / incomingMailsStore.pagination.size)
      } catch (e) {
        incomingMailsStore.pagination.page--
        if (e?.code !== 'ERR_CANCELED') {
          // Object.keys(incomingMailsStore.pagination).forEach(key => {
          //   incomingMailsStore.pagination[key] = previousPaginationState[key]
          // })
          if (toast) toastError(toast)
          throw e
        }
      } finally {
        incomingMailsStore.pagination.isLoading = false
      }
    },
  }
}
