<template>
  <b-card-body class="p-0 d-flex flex-column">
    <div class="px-1 py-50">
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="mb-0 text-truncate mr-50">
          {{ $t('classification~mail~list') }}
        </h5>
        <div>
          <!-- <button
            id="mail-list-refresh"
            class="btn p-0"
            @click="refresh"
          >
            <img
              :src="refreshIcon"
              height="24"
              alt="Refresh"
            >
          </button>
          <b-tooltip
            triggers="hover"
            target="mail-list-refresh"
            title="Refresh"
          /> -->
          <b-dropdown
            id="mail-list-filters"
            variant="dark"
            class="dropdown-filter"
            no-caret
          >
            <template #button-content>
              <feather-icon icon="SlidersIcon" size="14" />
            </template>
            <b-dropdown-header>Status</b-dropdown-header>
            <b-dropdown-item
              v-for="f in statusFilters"
              :key="`mail-status-filter-${f.label}`"
              @click="store.pagination.status = f.value"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-truncate block" style="max-width: 180px">{{ $t(f.label) }}</span>
                <feather-icon
                  v-if="store.pagination.status === f.value"
                  size="16"
                  icon="CheckIcon"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-header>Order</b-dropdown-header>
            <b-dropdown-item
              v-for="f in orderFilters"
              :key="`mail-status-filter-${f.label}`"
              @click="store.pagination.order = f.value"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-truncate block" style="max-width: 180px">{{ $t(f.label) }}</span>
                <feather-icon
                  v-if="store.pagination.order === f.value"
                  size="16"
                  icon="CheckIcon"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>
          <b-tooltip
            triggers="hover"
            target="mail-list-filters"
            title="Filters"
          />
        </div>
      </div>
    </div>
    <hr class="my-0">
    <search-input
      :value.sync="store.pagination.search"
      no-max-width
    />
    <div
      ref="mailListScrollSection"
      class="flex-grow-1 position-relative"
      style="overflow-y: auto"
    >
      <div
        v-if="store.rows.pined.length > 0"
        class="p-50 w-100 bg-light text-dark font-weight-bold border position-sticky"
        style="top: 0; z-index: 2"
      >
        <feather-icon icon="FlagIcon" /> Pined
      </div>
      <b-list-group
        v-if="store.rows.list.length > 0"
        flush
      >
        <mail-item
          v-for="mail in store.rows.pined"
          :key="`mail-list-email-${mail.email_id}`"
          :mail="mail"
          pined
        />
      </b-list-group>
      <div
        v-if="store.rows.pined.length > 0"
        class="p-50 w-100 bg-light text-dark font-weight-bold border position-sticky"
        style="top: 0; z-index: 2"
      >
        List
      </div>
      <b-list-group
        v-if="store.rows.list.length > 0"
        flush
      >
        <mail-item
          v-for="mail in store.rows.list.filter(mail => !store.rows.pined.map(m => m.email_id).includes(mail.email_id))"
          :key="`mail-list-email-${mail.email_id}`"
          :mail="mail"
        />
      </b-list-group>
      <div
        v-else-if="store.pagination.isLoading && store.rows.list.length === 0"
        class="p-1"
      >
        <div
          v-for="(_, i) in Array(10).fill('')"
          :key="`mail-skeleton-${i}`"
          class="d-flex w-100"
        >
          <b-skeleton type="avatar" class="flex-shrink-0" />
          <div class="ml-50 flex-grow-1">
            <b-skeleton animation="throb" width="85%"/>
            <b-skeleton animation="throb" width="55%"/>
            <b-skeleton animation="throb" width="70%"/>
          </div>
        </div>
      </div>
      <div v-else class="p-2">
        <no-data />
      </div>
      <div
        v-if="store.pagination.isLoading && store.rows.list.length > 0"
        class="d-flex justify-content-center align-items-center py-2"
      >
        <b-spinner label="Spinning"/>
      </div>
      <!-- <observer
        v-if="!store.pagination.isLoading"
        :options="{
          root: null,
          rootMargin: '0px',
          threshold: 0.5
        }"
        @intersect="loadMore"
      /> -->
    </div>
  </b-card-body>
</template>

<script>
import refreshIcon from '@/assets/images/icons/refresh.svg'
import {
  BListGroup,
  BCardBody,
  BSkeleton,
  BDropdownHeader,
  BDropdownDivider,
  BTooltip,
} from 'bootstrap-vue'
import MailItem from './MailItem.vue'
import SearchInput from '../../../CustomComponents/DataTable/components/SearchInput.vue'
import NoData from '../../../CustomComponents/NoData/NoData.vue'
import useIncomingMailStore from '../incomingMailsStore'

const { store, listData } = useIncomingMailStore()

const statusFilters = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Not proceeded',
    value: 0,
  },
  {
    label: 'In progress',
    value: 2,
  },
  {
    label: 'Processed',
    value: 1,
  },
]

const orderFilters = [
  {
    label: 'Asc',
    value: 'a',
  },
  {
    label: 'Desc',
    value: 'd',
  },
]

export default {
  name: 'MailList',
  components: {
    MailItem,
    BListGroup,
    SearchInput,
    BCardBody,
    NoData,
    BSkeleton,
    BDropdownHeader,
    BDropdownDivider,
    BTooltip,
  },
  data() {
    return {
      store,
      statusFilters,
      orderFilters,
      refreshIcon,
    }
  },
  watch: {
    'store.pagination.search': function () {
      store.pagination.page = 0
      store.rows.list = []
      this.loadMore()
    },
    'store.pagination.order': function () {
      store.pagination.page = 0
      store.rows.list = []
      this.loadMore()
    },
    'store.pagination.status': function () {
      store.pagination.page = 0
      store.rows.list = []
      this.loadMore()
    },
  },
  mounted() {
    this.loadMore()
    this.$refs.mailListScrollSection.addEventListener('scroll', this.loadMoreOnThEnd)
  },
  destroyed() {
    this.$refs.mailListScrollSection.removeEventListener('scroll', this.loadMoreOnThEnd)
  },
  methods: {
    loadMoreOnThEnd() {
      if (this.$refs.mailListScrollSection.scrollTop + this.$refs.mailListScrollSection.clientHeight >= this.$refs.mailListScrollSection.scrollHeight - 400) {
        this.loadMore()
      }
    },
    getData(loadMore) {
      listData({
        api: this.$http,
        loadMore,
        infiniteLoadStyle: true,
        resolveData: data => data.items,
        toast: this.$toast,
        url: '/emails',
      })
    },
    refresh() {
      store.pagination.page = 0
      store.rows.list = []
      this.getData()
    },
    loadMore() {
      this.getData(true)
    },
  },
}
</script>
