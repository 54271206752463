<template>
  <div>
    <div v-if="currentItem" class="mb-2">
      <Teleport to="body">
        <loading-full v-if="loadingColumn" />
      </Teleport>
      <div class="d-flex justify-content-between">
        <div>
          <h4>{{ $t(`classification~classify~this~${email ? 'mail' : 'document'}`) }}<strong class="text-primary"> #{{ email ? currentMail ? currentMail.email_id : '' : currentDoc ? currentDoc.document_id : '' }}</strong></h4>
        </div>
        <div class="flex-shrink-0">
          <b-badge :variant="statusClass(currentItem)">
            {{ $t('classification~status~' + getItemStatus(currentItem)) }}
          </b-badge>
        </div>
      </div>
      <small class="text-secondary">{{ $t(`classification~classify~this~${email ? 'mail' : 'document'}~description`) }}</small>
    </div>
    <div class="d-flex w-100 mb-2">
      <button
        v-for="(step, i) in steps"
        :key="`${step.label}${i}`"
        class="d-flex flex-column justify-content-center align-items-center flex-grow-1 pb-50"
        :class="reachedStepIndex >= i && 'text-primary'"
        :style="{
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: '2px solid',
          borderColor: currentStepIndex === i ? 'var(--primary)' : 'var(--light)',
          opacity: currentStepIndex >= i ? 1 : 0.3,
          cursor: reachedStepIndex >= i ? 'pointer' : 'not-allowed',
        }"
        @click="changeStep(i)"
      >
        <feather-icon size="18" :icon="step.icon" />
        <small>{{ $t(step.label) }}</small>
      </button>
    </div>
    <b-row
      v-for="(currentFields, i) in fieldsList"
      :key="`current-fields-list-${i}`"
      style="gap: 10px;"
    >
      <field
        v-for="field in currentFields"
        v-show="currentStepIndex === i"
        :key="`field-${field.key}`"
        :field="field"
        :entity="currentItem"
      />
    </b-row>
  </div>
</template>

<script>
import Field from '@/views/app/Generic/Field.vue'
import Teleport from 'vue2-teleport'
import LoadingFull from './LoadingFull.vue'
import useIncomingMailStore from '../incomingMailsStore'
import { getItemStatus, statusClass } from '../utils'

const { store } = useIncomingMailStore()

export default {
  name: 'ClassificationComponent',
  components: {
    Field,
    Teleport,
    LoadingFull,
  },
  props: {
    email: {
      type: Boolean,
      default: false,
    },
    loadingColumn: {
      type: Boolean,
      default: false,
    },
    fieldsList: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    reachedStepIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      entity: {},
      steps: [
        {
          label: 'classification~company',
          icon: 'MailIcon',
        },
        {
          label: 'classification~ticket',
          icon: 'TagIcon',
        },
        {
          label: 'classification~priority',
          icon: 'AlertTriangleIcon',
        },
      ],
      currentItem: this.email ? store.rows.current : store.currentDocument,
      store,
    }
  },
  computed: {
    currentDoc: () => store.currentDocument,
    currentMail: () => store.rows.current,
  },
  watch: {
    'currentItem.ticket_id': function (val) {
      if (!val) {
        this.$set(this.currentItem, 'board_id', null)
        this.$set(this.currentItem, 'contract_id', null)
        this.$set(this.currentItem, 'currentTicket', null)
      }
    },
    'currentItem.documenttype_id': function () {
      this.onDocumentTypeChange()
    },
    'currentItem.pos_id': function () {
      if (this.currentItem.contract_id) this.$set(this.currentItem, 'contract_id', null)
      if (this.currentItem.ticket_id) this.$set(this.currentItem, 'ticket_id', null)
    },
    'currentItem.create_subticket': function (val) {
      if (val) {
        this.$set(this.currentItem, 'priority_id', null)
        this.$set(this.currentItem, 'board_id', null)
      } else {
        this.$set(this.currentItem, 'priority_id', this.currentItem?.currentTicket?.priority_id)
        this.$set(this.currentItem, 'board_id', this.currentItem?.currentTicket?.board_id)
      }
    },
  },
  destroyed() {
    store.currentDocument = null
    store.rows.current = store.rows.list.find(row => row?.email_id === this.currentMail?.email_id)
  },
  methods: {
    getItemStatus,
    statusClass,
    changeStep(index) {
      if (index <= this.reachedStepIndex) {
        this.$emit('update:currentStepIndex', index)
      }
    },
  },
}
</script>
