<template>
  <div
    class="pb-2 w-100"
  >
    <div class="d-flex align-items-stretch" style="gap: 1rem;">
      <div class="flex-shrink-0 w-100 mail-list-wrapper">
        <b-card
          no-body
          class="h-100 mb-0"
          :style="`max-height: ${maxHeight}`"
        >
          <mail-list />
        </b-card>
      </div>
      <div class="flex-grow-1">
        <b-card
          no-body
          class="h-100 mb-0 p-0"
          :style="`max-height: ${maxHeight}`"
        >
          <b-card-body
            v-if="store.rows.current"
            class="p-0"
          >
            <mail-content />
          </b-card-body>
          <b-card-body v-else class="d-flex flex-column text-center justify-content-center align-items-center">
            <img :src="EmailOpen" style="max-width: 80px; object-fit: content;" :alt="$t('classification~no~mail~selected')" >
            <h4 class="mt-2">
              {{ $t('classification~no~mail~selected') }}
            </h4>
            <small>{{ $t("select~a~mail~text") }}</small>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import EmailOpen from '@/assets/images/icons/email-open.svg'
import MailContent from './components/MailContent.vue'
import MailList from './components/MailList.vue'
import useIncomingMailStore from './incomingMailsStore'

const { store } = useIncomingMailStore()

export default {
  components: {
    BCard,
    BCardBody,
    MailContent,
    MailList,
  },
  data() {
    return {
      EmailOpen,
      store,
      maxHeight: 'calc(100vh - var(--header-with-breadcrumb-height))',
    }
  },
}
</script>

<style>
.dropdown-filter .btn {
  padding: 8px !important;
}
.ps__rail-y {
  z-index: 1;
}
.mail-list-wrapper {
  max-width: 28rem;
}
</style>
