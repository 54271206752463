var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.currentItem)?_c('div',{staticClass:"mb-2"},[_c('Teleport',{attrs:{"to":"body"}},[(_vm.loadingColumn)?_c('loading-full'):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t(("classification~classify~this~" + (_vm.email ? 'mail' : 'document'))))),_c('strong',{staticClass:"text-primary"},[_vm._v(" #"+_vm._s(_vm.email ? _vm.currentMail ? _vm.currentMail.email_id : '' : _vm.currentDoc ? _vm.currentDoc.document_id : ''))])])]),_c('div',{staticClass:"flex-shrink-0"},[_c('b-badge',{attrs:{"variant":_vm.statusClass(_vm.currentItem)}},[_vm._v(" "+_vm._s(_vm.$t('classification~status~' + _vm.getItemStatus(_vm.currentItem)))+" ")])],1)]),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.$t(("classification~classify~this~" + (_vm.email ? 'mail' : 'document') + "~description"))))])],1):_vm._e(),_c('div',{staticClass:"d-flex w-100 mb-2"},_vm._l((_vm.steps),function(step,i){return _c('button',{key:("" + (step.label) + i),staticClass:"d-flex flex-column justify-content-center align-items-center flex-grow-1 pb-50",class:_vm.reachedStepIndex >= i && 'text-primary',style:({
        backgroundColor: 'transparent',
        border: 'none',
        borderBottom: '2px solid',
        borderColor: _vm.currentStepIndex === i ? 'var(--primary)' : 'var(--light)',
        opacity: _vm.currentStepIndex >= i ? 1 : 0.3,
        cursor: _vm.reachedStepIndex >= i ? 'pointer' : 'not-allowed',
      }),on:{"click":function($event){return _vm.changeStep(i)}}},[_c('feather-icon',{attrs:{"size":"18","icon":step.icon}}),_c('small',[_vm._v(_vm._s(_vm.$t(step.label)))])],1)}),0),_vm._l((_vm.fieldsList),function(currentFields,i){return _c('b-row',{key:("current-fields-list-" + i),staticStyle:{"gap":"10px"}},_vm._l((currentFields),function(field){return _c('field',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentStepIndex === i),expression:"currentStepIndex === i"}],key:("field-" + (field.key)),attrs:{"field":field,"entity":_vm.currentItem}})}),1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }