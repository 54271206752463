<template>
  <div
    class="d-flex justify-content-center align-items-center bg-dark position-fixed"
    style="opacity: 0.3; top: 0; left: 0; right: 0; bottom: 0;"
  >
    <b-spinner />
  </div>
</template>

<script>
export default {
  name: 'LoadingFull',
}
</script>
