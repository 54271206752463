<template>
  <b-card
    class="my-0 card-body-padding position-relative"
    :class="{
      active,
      'user-select-none cursor-pointer': pointer,
      'py-50 pr-50': isProcess(document),
      'bg-light': isVcf,
    }"
    @click.stop="$emit('on-select', document)"
  >
    <div class="d-flex flex-column align-items-center" style="gap: 6px;">
      <b-badge
        v-if="showStatus && !isVcf"
        :variant="statusClass(document)"
        :class="`ml-auto`"
        style="top: -12px; right: -5px; font-size: 8px;"
      >
        {{ $t('classification~status~' + getDocumentStatus(document)) }}
      </b-badge>
    </div>
    <div class="d-flex flex-wrap align-items-center pr-50" style="gap: 8px">
      <div class="d-flex align-items-center" style="gap: 8px">
        <img v-if="!isVcf" :src="pdfFile" alt="Pdf icon" >
        <feather-icon v-else icon="UserIcon" size="20" />
        <span
          class="text-sm text-truncate"
          style="word-break: break-all; max-width: 250px;"
        >
          {{ document && document.document_name }}
        </span>
        <b-dropdown
          v-if="!isProcess(document) && !loading && !isVcf"
          id="more-dropdown"
          variant="light"
          class="settings-dropdown btn-outline"
          no-caret
        >
          <template #button-content>
            <div class="position-relative">
              <feather-icon icon="MoreVerticalIcon" size="14" />
            </div>
          </template>
          <b-dropdown-item
            @click.stop="$emit('on-select', document)"
          >
            <div
              class="d-flex align-items-center"
              style="gap: 16px;"
            >
              <feather-icon icon="CheckIcon" class="text-success" size="14" />
              <span class="text-truncate block" style="max-width: 180px">{{ $t('classification~classify') }}</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            @click.stop="$emit('on-dismiss', document)"
          >
            <div
              class="d-flex align-items-center"
              style="gap: 16px;"
            >
              <feather-icon icon="XIcon" class="text-danger" size="14" />
              <span class="text-truncate block" style="max-width: 180px">{{ $t('classification~dismiss') }}</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          v-if="isVcf"
          v-b-tooltip.hover
          size="sm"
          class="px-50"
          variant="dark"
          :title="$t('classification~download')"
          @click.stop="downloadDocument"
        >
          <feather-icon icon="ArrowDownIcon" />
        </b-button>
        <b-spinner v-if="loading" />
      </div>
    </div>
  </b-card>
</template>

<script>
import { getDocumentLink } from '@/libs/utils'
import pdfFile from '@/assets/images/icons/pdf-file.svg'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import {
  statusClass,
  getDocumentStatus,
  isProcess,
  isVcf,
} from '../utils'

export default {
  name: 'FileCard',
  components: {
    BDropdownItem,
    BDropdown,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
    showChevronRight: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pdfFile,
    }
  },
  computed: {
    isVcf() { return isVcf(this.document) },
  },
  methods: {
    statusClass,
    getDocumentStatus,
    isProcess,
    getDocumentLink,
    downloadDocument() {
      window.open(getDocumentLink(this.document))
    },
  },
}
</script>

<style>
.card-body-padding {
  border: 2px solid var(--light);
}
.card-body-padding.active {
  border-color: red;
}
.card-body-padding .card-body {
  padding: 0 0 6px 14px !important;
}
.settings-dropdown .btn {
  padding: 7px;
}
</style>
