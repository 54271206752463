import moment from 'moment-business-time'

const DOCUMENT_STATUS = {
  dismiss: 'dismiss',
  done: 'done',
  notProcess: 'notprocess',
}

const MAIL_STATUS = {
  dismiss: 'dismiss',
  done: 'done',
  processed: 'processed',
}

export const CLASSIFICATION_ACTIONS = {
  preclassification: 'preclassification',
  classify: 'classify',
  dismiss: 'dismiss',
}

export function isVcf(document) {
  if (!document?.document_name) {
    return false
  }

  const nameParts = document.document_name.split('.')
  return String(nameParts[nameParts.length - 1]).toLowerCase() === 'vcf'
}

export const getAttachmentUrl = docId => `https://api.dev-zelos.de/documents/preview/${docId}`

export function isDismissed(item) {
  return item?.document_id ? !!item.classification_dismissed : !!item?.email_dismissed
}
export function isDone(item) {
  return item?.document_id ? !!item.ticket_created : item?.email_processed && !item?.email_dismissed
}

export const isProcess = item => isDismissed(item) || isDone(item)

export const canClickClassify = item => item && !!((item.pos_id || item.pos_id === 0) && (item.contract_id || item.contract_id === 0) && (item.board_id || item.board_id === 0) && (item.priority_id || item.priority_id === 0) && (item.document_id ? (item.documenttype_id || item.documenttype_id === 0) : true))

export const getMailAttachments = item => (item
  ? (item?.documents || [])
    .filter(
      // doc => RegExp(new RegExp(`src=['"].*${doc.document_name}['"]`)).exec(String(item.email_body || '')),
      doc => !isVcf(doc),
    )
  : [])

export const getMailVcfDocs = item => (item
  ? (item?.documents || [])
    .filter(
      // doc => RegExp(new RegExp(`src=['"].*${doc.document_name}['"]`)).exec(String(item.email_body || '')),
      doc => isVcf(doc),
    )
  : [])

export const canClassify = item => !isProcess(item) && getMailAttachments(item).length === 0

// eslint-disable-next-line no-nested-ternary
export const getDocumentStatus = document => (document.classification_dismissed
  ? DOCUMENT_STATUS.dismiss
  : document.ticket_created
    ? DOCUMENT_STATUS.done
    : DOCUMENT_STATUS.notProcess)

// eslint-disable-next-line no-nested-ternary
export const getMailStatus = item => (item.email_dismissed
  ? 'dismiss'
  // eslint-disable-next-line no-nested-ternary
  : item.email_processed
    ? 'done'
    : item.documents?.findIndex(d => getDocumentStatus(d) !== 'notprocess') >= 0
      ? 'inprogress'
      : 'notprocess')

export const getItemStatus = item => (item.document_id ? getDocumentStatus(item) : getMailStatus(item))

export function getMailStatusLabel(mail) {
  const status = getMailStatus(mail)
  return status === MAIL_STATUS.dismiss || status === MAIL_STATUS.done ? MAIL_STATUS.processed : status
}

export async function buildClassifyPayload(item, action, api, errorToast, parentEmail) {
  if (!item?.ticket_id && action !== CLASSIFICATION_ACTIONS.dismiss) {
    if (!item?.pos_id) return errorToast('Please select a pos')
    // if (!item.contract_id) return errorToast('Please select a contract')
    if (!item?.board_id) return errorToast('Please select a board')
  }
  // get first column of selected board
  const column = (
    await api({
      entity: 'frontend_column_list',
      action: 'read-rich',
      order_by: 'rank_order',
      order_dir: 'ASC',
      data: [{ board_id: item.board_id }],
    })
  ).data.data.data[0]

  const now = moment()
  // const user = getUserData()
  const deadline = now
    .clone()
    .addWorkingTime(column.default_deadline_period || 0, 'hours')
    .format('YYYY-MM-DD HH:mm:ss')
  const deadline_yellow = now
    .clone()
    .addWorkingTime(column.default_deadline_yellow || 0, 'hours')
    .format('YYYY-MM-DD HH:mm:ss')
  const deadline_red = now
    .clone()
    .addWorkingTime(column.default_deadline_red || 0, 'hours')
    .format('YYYY-MM-DD HH:mm:ss')

  const _payload = {
    pos_id: item.pos_id,

    board_id: item.board_id,

    // column_id: column.column_id,
    ticket_move_time_in: now.format('YYYY-MM-DD HH:mm:ss'),
    ticket_deadline_offset: deadline,
    ticket_deadline_offset_yellow: deadline_yellow,
    ticket_deadline_offset_red: deadline_red,

    ticket_id_group: item.ticket_id,

    email_id: parentEmail.email_id,

    contract_id: item.contract_id,
    document_id: item.document_id,
    documenttype_id: item.documenttype_id,
    priority_id: item.priority_id,

    action,
    create_subticket: !item.ticket_id ? true : !!item.create_subticket,
  }

  const payload = {}
  Object.keys(_payload).forEach(key => {
    if (typeof _payload[key] !== typeof undefined) {
      payload[key] = _payload[key]
    }
  })

  return payload
}

export function statusClass(item) {
  const status = item.document_id ? getDocumentStatus(item) : getMailStatus(item)
  switch (status) {
    case 'inprogress':
      return 'warning'
    case 'done':
      return 'success'
    case 'dismiss':
      return 'danger'
    default:
      return ''
  }
}
