<template>
  <div class="h-100 w-100 d-flex flex-column position-relative" style="overflow-y: auto">
    <Teleport to="body">
      <loading-full v-if="loadingColumn" />
    </Teleport>
    <div
      class="d-flex flex-wrap align-items-center border-bottom w-100 py-50 px-2 position-sticky bg-white"
      style="gap: 8px; top: 0; z-index: 1"
    >
      <b-button
        v-b-tooltip.hover
        :title="$t(`classification~${isPined(currentMail) ? 'unpin' : 'pin'}`)"
        class="p-50"
        :variant="isPined(currentMail) ? `dark` : `light`"
        @click="togglePin(currentMail)"
      >
        <feather-icon icon="FlagIcon" />
      </b-button>
      <div class="mx-50" style="height: 100%; width: 1px; background-color: var(--light)" />
      <a id="mail-content-web-link" target="_blank" :href="currentMail.email_weblink || ''">
        <b-button class="p-50" variant="light">
          <feather-icon icon="LinkIcon" />
        </b-button>
      </a>
      <b-tooltip triggers="hover" target="mail-content-web-link" title="Web link" />
      <div class="mx-50" style="height: 100%; width: 1px; background-color: var(--light)" />
      <fragment v-if="isProcess(currentMail) && (currentMail.ticket_id_created || currentMail.contract_id || currentMail.board_id)">
        <b-dropdown
          id="more-dropdown"
          variant="light"
          class="settings-dropdown btn-outline"
          no-caret
        >
          <template #button-content>
            <div class="position-relative">
              <feather-icon icon="MoreVerticalIcon" size="14" />
            </div>
          </template>
          <b-dropdown-item v-if="currentMail.ticket_id_created" @click="$router.push(`/app/table/ticket/view/${currentMail.ticket_id_created}`)">
            <router-link
              class="text-secondary"
              target="_blank"
              :to="{ name: 'table-view', params: { table: 'ticket', id: currentMail.ticket_id_created } }"
            >
              <feather-icon icon="TagIcon" /> {{ $t('classification~open~ticket') }}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item v-if="currentMail.contract_id" @click="$router.push(`/app/table/contract/view/${currentMail.contract_id}`)">
            <feather-icon icon="ClipboardIcon" /> {{ $t('classification~open~contract') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="currentMail.board_id" @click="$router.push(`/app/table/board/${currentMail.board_id}/kanban`)">
            <feather-icon icon="LayoutIcon" /> {{ $t('classification~open~board') }}
          </b-dropdown-item>
        </b-dropdown>
        <div class="mx-50" style="height: 100%; width: 1px; background-color: var(--light)" />
      </fragment>
      <b-badge :variant="statusClass(currentMail)">
        {{
          $t("classification~status~" + (currentMail.email_id ? getMailStatusLabel(currentMail) : currentMail.status))
        }}
      </b-badge>
      <div class="ml-auto" />
      <b-dropdown
        v-if="canClassify(currentMail) && !loadingColumn"
        id="more-dropdown"
        variant="light"
        class="settings-dropdown btn-outline"
        no-caret
      >
        <template #button-content>
          <div class="position-relative">
            <feather-icon icon="MoreVerticalIcon" size="14" />
          </div>
        </template>
        <b-dropdown-item
          @click="showMailModal(currentMail)"
        >
          <div
            class="d-flex align-items-center"
            style="gap: 16px;"
          >
            <feather-icon icon="CheckIcon" class="text-success" size="14" />
            <span class="text-truncate block" style="max-width: 180px">{{ $t('classification~classify') }}</span>
          </div>
        </b-dropdown-item>
        <b-dropdown-item
          @click="classify(currentMail, CLASSIFICATION_ACTIONS.dismiss)"
        >
          <div
            class="d-flex align-items-center"
            style="gap: 16px;"
          >
            <feather-icon icon="XIcon" class="text-danger" size="14" />
            <span class="text-truncate block" style="max-width: 180px">{{ $t('classification~dismiss') }}</span>
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <b-spinner v-if="loadingColumn" />
      <div class="mx-50" style="height: 100%; width: 1px; background-color: var(--light)" />
      <b-button id="mail-content-close" class="p-50" variant="dark" @click="store.rows.current = null">
        <feather-icon icon="XIcon" />
      </b-button>
      <b-tooltip triggers="hover" target="mail-content-close" title="Close" />
    </div>
    <div class="d-flex align-items-start px-2 py-1">
      <b-avatar
        variant="dark"
        :text="currentMail.email_from ? currentMail.email_from.charAt(0) : 'A'"
        class="mr-2 flex-shrink-0"
        size="4rem"
      />
      <div class="text-left d-flex flex-column flex-grow-1">
        <div class="d-flex align-items-start justify-content-between">
          <div class="mr-50">
            <h3 class="mb-0">
              {{ currentMail.email_from }}
            </h3>
            <small class="text-secondary">
              {{ moment(currentMail.email_received_datetime).calendar() }} - <strong>#{{ currentMail.email_id }}</strong>
            </small>
            <div>
              <span
              >{{ $t('classification~subject') }}: <strong>{{ currentMail.email_subject }}</strong></span
              >
            </div>
            <div v-if="currentMail.email_cc">
              <span class="d-inline-block mt-50">{{ $t('classification~cc') }}:</span>
              <b-badge
                v-for="(cc, index) in currentMail.email_cc.split(',').filter((k) => k)"
                :key="`mail-content-cc-${cc}-${index}`"
                variant="light"
                class="ml-50 mt-50 text-dark"
              >
                {{ cc }}
              </b-badge>
            </div>
          </div>
        </div>
        <transition name="slide">
          <div
            v-if="currentMailAttachments.length > 0"
            class="d-flex align-items-center flex-wrap mt-2"
            style="gap: 16px"
          >
            <button
              v-for="doc in currentMailAttachments.slice(0, showAllAttachments ? currentMailAttachments.length : 2)"
              :key="`email-document-${doc.document_id}`"
              class="border-0 outline-0"
              style="background: transparent"
            >
              <file-card
                pointer
                show-status
                show-chevron-right
                :document="doc"
                :loading="loadingColumn"
                @on-select="showDocumentModal(doc)"
                @on-dismiss="classify(doc, CLASSIFICATION_ACTIONS.dismiss)"
              />
            </button>
          </div>
        </transition>
        <div v-if="currentMailAttachments.length > 2" class="mt-50">
          <span class="text-info" role="button" @click="toggleShowAllAttachments">
            <feather-icon :icon="showAllAttachments ? 'ArrowUpIcon' : 'ArrowDownIcon'" />
            {{ $t(`classification~show~${showAllAttachments ? 'less' : 'all'}~attachments`) }}
          </span>
          <b-badge v-if="!showAllAttachments" variant="light" class="text-dark">
            + {{ currentMailAttachments.length - 2 }}
          </b-badge>
        </div>
      </div>
    </div>
    <div class="px-1 mx-50">
      <iframe
        ref="mailContentIframe"
        class="border-0 mt-50"
        style="font-size: 14px; line-height: 24px; overflow: visible; width: 100%"
        :srcdoc="mailBody"
        :height="mailContentIframeSize"
        :title="$t('classification~email')"
        @load="setIframeSize"
      />
    </div>
    <!-- VCF Files -->
    <div class="d-flex flex-wrap p-1" style="gap: 8px;">
      <div
        v-for="doc in currentMailVcfDocs"
        :key="`email-vcf-document-${doc.document_id}`"
        class="border-0 outline-0"
        style="background: transparent"
      >
        <file-card
          pointer
          show-status
          show-chevron-right
          :document="doc"
        />
      </div>
    </div>
    <modal
      :open.sync="store.isDocumentClassifyModalOpen"
      :ok-disabled="!canForward(store.currentDocument)"
      size="xl"
      :loading="loadingColumn"
      :ok-label="currentStepIndex === 2 ? 'classification~classify' : 'classification~next'"
      :ok-icon="currentStepIndex === 2 ? 'CheckIcon' : 'ArrowRightIcon'"
      :ok-icon-position="currentStepIndex === 2 ? 'left' : 'right'"
      :hide-ok="isProcess(store.currentDocument)"
      footer-class="justify-content-center flex-row-reverse"
      :props="{
        'header-class': 'bg-white border-bottom',
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
      }"
      :actions="[
        ...(!(store.currentDocument && store.currentDocument.document_name.split('.')[
          store.currentDocument.document_name.split('.').length - 1
        ]) ? [] : [{
          text: 'classification~print~document',
          icon: 'PrinterIcon',
          props: {
            variant: 'light',
          },
          onClick: printDocument,
        }]),
        {
          text: 'classification~download~document',
          icon: 'ArrowDownIcon',
          props: {
            variant: 'light',
          },
          onClick: downloadDocument,
        },
      ]"
      @ok-clicked="doNextAction(store.currentDocument)"
    >
      <div class="d-flex container mx-auto" style="gap: 16px">
        <div class="d-flex flex-grow-1 bg-dark justify-content-center align-items-center" style="aspect-ratio: 16/9">
          <img
            v-if="
              store.currentDocument &&
                ['jpg', 'png', 'jpeg'].includes(
                  store.currentDocument.document_name.split('.')[
                    store.currentDocument.document_name.split('.').length - 1
                  ]
                )
            "
            style="font-size: 14px; line-height: 24px; overflow: visible; max-width: 100%; object-fit: cover"
            :src="getAttachmentUrl(store.currentDocument ? store.currentDocument.document_id : '')"
            :alt="store.currentDocument.document_name"
          >
          <iframe
            v-else
            id="document-preview-iframe"
            :title="$t('classification~classify~this~document')"
            :src="getAttachmentUrl(store.currentDocument ? store.currentDocument.document_id : '')"
            width="100%"
            height="100%"
            style="border: 0"
          />
        </div>
        <div v-if="!isProcess(store.currentDocument)" class="flex-shrink-0 p-2" style="max-width: 35%">
          <ClassificationComponent
            v-if="store.currentDocument"
            :loading-column="loadingColumn"
            :fields-list="fieldsList"
            :current-step-index.sync="currentStepIndex"
            :reached-step-index.sync="reachedStepIndex"
            :checkbox-ticket-classification-entity="checkboxTicketClassificationEntity"
          />
        </div>
      </div>
    </modal>
    <modal
      :open.sync="store.isMailClassifyModalOpen"
      size="md"
      :ok-label="currentStepIndex === 2 ? 'classification~classify' : 'classification~next'"
      :ok-icon="currentStepIndex === 2 ? 'CheckIcon' : 'ArrowRightIcon'"
      :ok-icon-position="currentStepIndex === 2 ? 'left' : 'right'"
      :ok-disabled="!canForward(currentMail)"
      :hide-ok="isProcess(currentMail)"
      :loading="loadingColumn"
      footer-class="justify-content-center flex-row-reverse"
      :props="{
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
      }"
      :bread-crumb="[
        {
          text: `${$t('email')} #${currentMail ? currentMail.email_id : ''}`,
          active: true,
        },
        {
          text: $t('classification~classification'),
          active: true,
        },
      ]"
      @ok-clicked="doNextAction(currentMail)"
    >
      <ClassificationComponent
        v-if="currentMail"
        :loading-column="loadingColumn"
        :fields-list="fieldsList"
        :current-step-index.sync="currentStepIndex"
        :reached-step-index.sync="reachedStepIndex"
        email
        :checkbox-ticket-classification-entity="checkboxTicketClassificationEntity"
      />
    </modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BDropdownItem,
  BTooltip,
  VBToggle,
  BDropdown,
} from 'bootstrap-vue'
import Modal from '@/views/app/CustomComponents/Modal/Modal.vue'
import moment from 'moment'
import { handleConfirm } from '@/views/app/CustomComponents/DataTable/state/data-table-store'
import { getDocumentLink } from '@/libs/utils'
import Teleport from 'vue2-teleport'
import FileCard from './FileCard.vue'
import ClassificationComponent from './ClassificationComponent.vue'
import useIncomingMailStore from '../incomingMailsStore'
import {
  buildClassifyPayload,
  canClassify,
  getAttachmentUrl,
  getMailAttachments,
  getMailStatusLabel,
  isProcess,
  statusClass,
  CLASSIFICATION_ACTIONS,
  canClickClassify,
  getMailVcfDocs,
} from '../utils'
import LoadingFull from './LoadingFull.vue'

const { store, togglePin, isPined } = useIncomingMailStore()
const companyFieldsFn = entity => [
  {
    key: 'customergroup_id',
    type: 'list',
    list: 'customergroup',
    listLabel: 'customergroup_name',
    required: false,
    customPagination: {
      per_page: 15,
    },
  },
  {
    key: 'company_id',
    type: 'list',
    list: 'frontend_2_2_3_1',
    listLabel: 'company_name',
    filter_key: 'customergroup_id',
    required: false,
  },
  {
    key: 'pos_id',
    required: false,
    type: 'list',
    list: 'frontend_2_1_3_8',
    listLabel: 'pos_name',
    filter_key: 'company_id',
    entityCustomEndPoint: `/pos?company_id=${entity?.pos_id ?? ''}`,
    noLabel: true,
    noCache: true,
    placeholder: 'pos~name~custom_placeholder',
    noFetchOnInit: true,
    customPagination: {
      per_page: 15,
    },
  },
]

const ticketFields = [
  {
    key: 'is_ticket_classification',
    type: 'boolean',
    cols: 6,
    inline: true,
  },
  {
    key: 'create_subticket',
    type: 'boolean',
    cols: 5,
    inline: true,
    visible: entity => !!entity?.is_ticket_classification,
  },
  {
    key: 'ticket_id',
    type: 'list',
    onOptionSelected: (option, entity) => {
      entity.currentTicket = option
      entity.board_id = option.board_id
      entity.contract_id = option.ticket_contract_id
      if (!!entity?.is_ticket_classification && !entity?.create_subticket) {
        entity.priority_id = option.priority_id
      }
    },
    list: 'frontend_6_1_6_overview',
    entityCustomEndPoint: '/tickets/slims',
    listLabel: item => `${item.ticket_id ?? ''}${(item.ticket_id && item.ticket_name) ? ' - ' : ''}${item.ticket_name ?? ''}`,
    required: false,
    filter_key: 'pos_id',
    visible: entity => !!entity?.is_ticket_classification,
    noCache: true,
    customPagination: {
      data: [{ size: 15 }],
    },
    customFilter: {
      ticket_closed: 0,
    },
    // optionWithTooltipDetail: true,
  },
  {
    key: 'contract_id',
    listLabel: 'contract_name',
    type: 'list',
    list: 'frontend_4_2_1_contract_selector',
    filter_key: 'pos_id',
    required: false,
    disableFn: entity => !!entity?.is_ticket_classification,
  },
  {
    key: 'documenttype_id',
    type: 'list',
    list: 'documenttype',
    listLabel: 'documenttype_name',
    noFetch: false,
    visible: entity => !!entity?.document_id,
  },
]

const priorityFields = [
  {
    key: 'board_id',
    type: 'list',
    list: 'board',
    listLabel: 'board_name',
    noFetch: false,
    disableFn: entity => (!!entity?.is_ticket_classification && !entity?.create_subticket),
  },
  {
    key: 'priority_id',
    type: 'list',
    list: 'priority',
    listLabel: 'priority_name',
    required: false,
    disableFn: entity => (!!entity?.is_ticket_classification && !entity?.create_subticket),
  },
  // {
  //   key: 'fulfilment_rate_computed',
  //   type: 'boolean',
  //   cols: 5,
  //   inline: true,
  // },
  // {
  //   key: 'columns',
  //   type: 'list',
  //   multiple: true,
  //   list: 'frontend_column_list',
  //   listLabel: 'column_name',
  //   filter_key: 'board_id',
  //   noFetch: false,
  //   visible: entity => !!entity?.fulfilment_rate_computed,
  // },
  // {
  //   key: 'dead_line',
  //   label: 'Dead line',
  //   type: 'date',
  //   required: false,
  //   hideOnForm: true,
  // },
]

export default {
  name: 'MailContent',
  components: {
    BAvatar,
    FileCard,
    Modal,
    ClassificationComponent,
    BTooltip,
    Teleport,
    LoadingFull,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      CLASSIFICATION_ACTIONS,
      showAllAttachments: false,
      isMailClassifyModalOpen: false,
      store,
      moment,
      mailContentIframeSize: 0,
      currentStepIndex: 0,
      reachedStepIndex: 0,
      loadingColumn: false,
      checkboxTicketClassificationEntity: {
        is_ticket_classification: false,
        shouldCreateSubTicket: false,
      },
    }
  },
  computed: {
    fieldsList() { return [companyFieldsFn(this.isMailClassifyModalOpen ? store.rows.current : store.currentDocument), ticketFields, priorityFields] },
    currentDocument: () => store.currentDocument,
    currentMail: () => store.rows.current,
    documentModalBreadCrumb() {
      return [
        {
          text: `${this.$t('classification~email')} #${this.currentMail ? this.currentMail.email_id : ''}`,
          active: true,
        },
        {
          text: `${this.$t('classification~document')} #${store.currentDocument ? store.currentDocument.document_id : ''}`,
          active: true,
        },
        {
          text: this.$t('classification~classification'),
          active: true,
        },
      ]
    },
    mailBody() {
      let emailBody = String(store.rows.current?.email_body || '');
      (store.rows.current.documents || []).forEach(doc => {
        emailBody = emailBody.replace(
          new RegExp(`src=['"]cid:${doc.document_name || ''}.*['"]`, 'g'),
          `src="${getAttachmentUrl(doc.document_id)}" `,
        )
      })
      return emailBody
    },
    currentMailAttachments: () => getMailAttachments(store.rows.current),
    currentMailVcfDocs: () => getMailVcfDocs(store.rows.current),
  },
  watch: {
    'store.isMailClassifyModalOpen': function (val) {
      if (!val) {
        this.currentStepIndex = 0
      }
    },
    'store.isDocumentClassifyModalOpen': function (val) {
      if (!val) {
        this.currentStepIndex = 0
      }
    },
  },
  methods: {
    isPined,
    togglePin,
    statusClass,
    getMailStatusLabel,
    isProcess,
    canClickClassify,
    canClassify,
    canForward(itemToClassify) {
      switch (this.currentStepIndex) {
        case 0:
          return itemToClassify?.customergroup_id && itemToClassify?.company_id && itemToClassify?.pos_id
        case 1:
          return (itemToClassify?.document_id ? itemToClassify.documenttype_id : true) && itemToClassify?.contract_id && (itemToClassify?.is_ticket_classification ? itemToClassify.ticket_id : true)
        case 2:
          return this.canClickClassify(itemToClassify)
        default:
          return false
      }
    },
    doNextAction(itemToClassify) {
      if (this.currentStepIndex === 2) {
        this.classify(itemToClassify, CLASSIFICATION_ACTIONS.classify)
      } else {
        this.currentStepIndex++
        if (this.currentStepIndex >= this.reachedStepIndex) {
          this.reachedStepIndex = this.currentStepIndex
        }
      }
    },
    async classify(item, action) {
      if (this.loadingColumn) return

      try {
        this.loadingColumn = true

        const body = await buildClassifyPayload(
          item,
          action,
          this.$api,
          this.$errorToast,
          this.currentMail,
        )

        if (!body) {
          return
        }

        const data = await handleConfirm({
          swal: this.$swal,
          http: this.$http,
          method: 'put',
          url: '/tickets/classification',
          body,
        })

        store.isDocumentClassifyModalOpen = false
        store.isMailClassifyModalOpen = false

        const ticket_id = data.ticket_id
        const master_ticket_id = item.ticket_id || ticket_id
        if (item.document_id) {
          // if is a document
          this.$set(item, action === 'dismiss' ? 'classification_dismissed' : 'ticket_created', true)
          this.$set(item, 'processed', true)
          if (Array.isArray(this.currentMail?.documents)) {
            const documentIndex = this.currentMail.documents.findIndex(doc => doc.document_id === item.document_id)
            if (documentIndex > -1) {
              this.currentMail.documents[documentIndex] = { ...item }
            }
          }
        } else {
          // If is a mail
          this.$set(
            item,
            action === 'dismiss' ? 'email_dismissed' : 'ticket_id_created',
            action === 'dismiss' ? true : master_ticket_id,
          )
        }

        if (item.ticket_id) this.$set(item, 'subticket_id_created', ticket_id)

        // Make the mail proceed if it should be
        const currentEmail = this.currentMail
        if (currentEmail) {
          currentEmail.email_processed = currentEmail.documents?.length > 0
            ? !currentEmail.documents.some(doc => !isProcess(doc))
            : true
        }
        const mailIndex = store.rows.list.findIndex(mail => mail.email_id === currentEmail?.email_id)
        if (mailIndex > -1 && currentEmail) {
          store.rows.list[mailIndex] = { ...currentEmail }
        }
        this.$successToast(this.$t(
          // eslint-disable-next-line no-nested-ternary
          action === 'dismiss' ? 'classification~dismiss~success' : body.create_subticket ? 'classification~ticket~created' : 'classification~classification~success',
        ))

        store.currentDocument = null
        if (this.currentMail) {
          const isEmailHasBeenClassified = !!this.currentMail?.email_processed
          const currentFilterIsNotProcess = Number(store.pagination.status) === 0

          if (isEmailHasBeenClassified && currentFilterIsNotProcess) {
            // Remove selected mail
            store.rows.list = store.rows.list.filter(email => email.email_id !== this.currentMail?.email_id)
            store.rows.current = null
          }
        }
      } catch (e) {
        if (e.response) this.$errorToast(e.response.data.detail)
        else this.$errorToast(this.$t('classification~has~been~cancelled'))
      } finally {
        this.loadingColumn = false
      }
    },
    downloadDocument() {
      window.open(getDocumentLink(store.currentDocument), '_blank')
    },
    printDocument() {
      window.open(getAttachmentUrl(store.currentDocument ? store.currentDocument.document_id : ''))
    },
    toggleShowAllAttachments() {
      this.showAllAttachments = !this.showAllAttachments
    },
    showDocumentModal(doc) {
      store.currentDocument = doc
      store.isDocumentClassifyModalOpen = !!store.currentDocument
    },
    showMailModal(mail) {
      store.isMailClassifyModalOpen = !!mail
    },
    getAttachmentUrl,
    setIframeSize() {
      this.mailContentIframeSize = (this.$refs.mailContentIframe?.contentWindow.document.body?.getBoundingClientRect().height || 0) + 40
    },
  },
}
</script>
