<template>
  <transition name="slide">
    <b-list-group-item
      button
      class="px-2 border-0 outline-0 rounded-0 position-relative w-100 mail-item"
      :active="active"
      @click="store.rows.current = mail"
    >
      <div v-if="active" class="position-absolute h-100 bg-primary" style="width: 8px; left: 0; top: 0" />
      <div class="d-flex justify-content-end align-items-center" style="gap: 8px; margin-bottom: 4px;">
        <b-badge style="font-size: 8px;" :variant="statusClass(mail)">
          {{ $t('classification~status~' + (mail.email_id ? getMailStatusLabel(mail) :mail.status)) }}
        </b-badge>

        <b-button
          v-if="pined"
          class="p-0"
          variant="danger"
          @click.stop="unpin(mail)"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
      </div>
      <div class="d-flex align-items-start w-100">
        <b-avatar variant="dark" :text="mail.email_from ? mail.email_from.charAt(0) : 'A'" class="mr-1"/>
        <div class="text-left" style="width: calc(100% - 50px)">
          <div class="d-flex justify-content-between align-items-center" style="line-height: 48px;">
            <h5 class="mb-0 text-truncate pr-50">
              {{ mail.email_from }}
            </h5>
            <div>
              <div :class="{'show-on-not-hover': !pined}">
                <feather-icon
                  v-if="hasAttachments"
                  class="d-block mt-50"
                  icon="PaperclipIcon"
                  size="18"
                />
              </div>
              <div v-if="!pined" class="show-on-hover">
                <div class="d-flex" style="gap: 8px">
                  <button
                    class="border-0 outline-0 p-0 mail-action"
                    style="background: transparent"
                    @click="pin(mail)"
                  >
                    <feather-icon
                      class="d-block mt-50"
                      icon="FlagIcon"
                      size="18"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="d-block text-truncate pr-50">{{ mail.email_subject }}</span>
            <div class="text-secondary flex-shrink-0">
              {{ formatDate(mail.email_received_datetime) }}
            </div>
          </div>
          <span class="d-block text-secondary text-truncate">{{ mail.email_body_preview }}</span>
        </div>
      </div>
    </b-list-group-item>
  </transition>
</template>

<script>
import { BAvatar, BBadge, BListGroupItem } from 'bootstrap-vue'
import moment from 'moment'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import useIncomingMailStore from '../incomingMailsStore'
import { getMailAttachments, getMailStatusLabel, statusClass } from '../utils'

const { store, unpin, pin } = useIncomingMailStore()

export default {
  name: 'MailItem',
  components: {
    BAvatar,
    BListGroupItem,
    BBadge,
    FeatherIcon,
  },
  props: {
    mail: {
      type: Object,
      required: true,
    },
    pined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      store,
    }
  },
  computed: {
    active() { return store.rows.current && store.rows.current.email_id === this.mail?.email_id },
    hasAttachments() {
      return getMailAttachments(this.mail)?.length > 0
    },
  },
  methods: {
    unpin,
    pin,
    getMailStatusLabel,
    statusClass,
    formatDate(date) {
      const dateTime = moment(date)
        .toDate()
        .getTime()
      const dayBeginTime = moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .toDate()
        .getTime()
      const sevenDayBeforeTime = moment()
        .subtract(7, 'd')
        .toDate()
        .getTime()

      // eslint-disable-next-line no-nested-ternary
      return dateTime < sevenDayBeforeTime ? moment(date, true).format('MMM, DD')
        : dateTime < dayBeginTime
          ? moment(date, true).format('MMM, DD HH:mm A')
          : moment(date).format('LT')
    },
  },
}
</script>

<style>
.list-group .list-group-item-action.active {
    background-color: var(--light)!important;
    color: var(--secondary)!important;
    z-index: 0 !important;
}

.mail-item:not(:hover) .show-on-hover {
  display: none;
}

.mail-item:hover .show-on-not-hover {
  display: none;
}

.mail-action:not(:hover) {
  color: var(--secondary)
}
</style>
